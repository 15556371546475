import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from '../i18n/translateLoader';
import { HttpModule } from '@angular/http';
import { CommonService } from './services/common/common.service';
import { ApiAdminService } from './services/api-admin/api-admin.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import localeDE from "@angular/common/locales/id";

import { registerLocaleData } from "@angular/common";
registerLocaleData(localeDE);
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Network } from '@ionic-native/network/ngx';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, ReactiveFormsModule, HttpClientModule, HttpModule, TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    },
  }),],
  providers: [
    StatusBar,
    SplashScreen,
    ApiAdminService,
    InAppBrowser,
    Geolocation,
    OneSignal,
    Clipboard,
    FingerprintAIO,
    BarcodeScanner,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "id" },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
