import { Component, ViewChild } from '@angular/core';

import { Platform, ToastController, NavController, IonRouterOutlet, AlertController, ModalController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api/api.service';
import { APP_CONFIG } from 'src/const-variables/app.config';
import { UtilitiesService } from './services/utilities/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './services/configuration.service';

import { Location } from '@angular/common';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import { Plugins } from '@capacitor/core';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
const { SplashScreen } = Plugins;
import { Network } from '@ionic-native/network/ngx';
import { ModalConnectionPage } from './pages/modal-connection/modal-connection.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  storeViews: any = APP_CONFIG.STORE_VIEWS;

  langDefault: string = APP_CONFIG.LANGUAGE_DEFAULT;

  cmsBlocks = {
    topMenu: { en_US: '40', id_ID: '49' },
    about: { en_US: '41', id_ID: '44' },
    brand: { en_US: '42', id_ID: '42' },
    icon: { en_US: '43', id_ID: '43' }
  };
  appID: string = 'd00d31ab-0c8e-4513-b6d9-d6ba207e241c';
  firebaseID: any = '838761053161';


  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  categories: any = [];
  topMenuContent: any = [];
  aboutContent: any = [];
  brandContent: any = [];
  iconContent: any = false;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private utils: UtilitiesService,
    private configService: ConfigurationService,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private navCtrl: NavController,
    private oneSignal: OneSignal,
    private _location: Location,
    public alertController: AlertController,
    public faio: FingerprintAIO,
    public network: Network,
    public modalController: ModalController
  ) {
    this.initializeApp();
    this.getToken();
    this.show();
  }

  initializeApp() {
    this.platform.ready().then( () => {
      // let disconnectSubscription = this.network.onDisconnect().subscribe(async () => {
      //   console.log('network was disconnected :-(');
      //   const modal = await this.modalController.create({
      //     component: ModalConnectionPage,
      //   });
      //   modal.onDidDismiss().then((dataReturned) => {
      //   });
      //   return await modal.present();
      // });
      
      // // stop disconnect watch
      // disconnectSubscription.unsubscribe();
      
      
      // // watch network for a connection
      // let connectSubscription = this.network.onConnect().subscribe(() => {
      //   console.log('network connected!');
      //   // We just got a connection but we need to wait briefly
      //    // before we determine the connection type. Might need to wait.
      //   // prior to doing any api requests as well.
      //   setTimeout(() => {
      //     if (this.network.type === 'wifi') {
      //       console.log('we got a wifi connection, woohoo!');
      //     }
      //   }, 3000);
      // });
      
      // connectSubscription.unsubscribe();
      
      this.statusBar.styleDefault();
      SplashScreen.hide();

      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        console.log('Back press handler!');
        if (this._location.isCurrentPathEqualTo('/tabs/tab1')) {

          // Show Exit Alert!
          console.log('Show Exit Alert!');
          this.showExitConfirm();
          processNextHandler();
        } else {

          // Navigate to back page
          console.log('Navigate to back page');
          this._location.back();

        }

      });

      this.platform.backButton.subscribeWithPriority(5, () => {
        console.log('Handler called to force close!');
        this.alertController.getTop().then(r => {
          if (r) {
            navigator['app'].exitApp();
          }
        }).catch(e => {
          console.log(e);
        })
      });
      // this.backButton();

    });

    if (this.platform.is('android') || this.platform.is('ios')) {
      this.oneSignal.startInit(this.appID, this.firebaseID);

      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

      this.oneSignal.handleNotificationReceived().subscribe(res => console.log(res))

      this.oneSignal.handleNotificationOpened().subscribe(res => console.log(res))

      this.oneSignal.endInit();
    }

  }

  async show() {
    let status = await this.utils.getStorage({ key: 'fingerprint' })
    if (!status.value) {
      this.utils.setStorage(
        { key: 'fingerprint', value: 'Aktif' })
    }

    // let isActiveFingerprint = await this.utils.getStorage('fingerprint')
    // // Fingerprint or Face Auth is available
    // if (isActiveFingerprint.value == 'Aktif') {
    //   this.faio.show({
    //     title: 'Bigi Authentication',
    //     subtitle: 'Verifying',// This is required to read the document
    //   }).then((data: any) => {
    //     if (data === 'Success') {
    //       // Fingerprint/Face was successfully verified
    //       // Perform processing after successful verification here
    //       console.log('Ok', data);
    //       this.utils.alertMessage('Selamat Datang' + ' Ricky', 'success');
    //       // return this.nav.navigateRoot('/tabs/home');

    //     } else {
    //       // Fingerprint/Face was not successfully verified
    //       console.error('error', data);
    //       // return this.nav.navigateRoot('/login');
    //     }
    //   })
    //     .catch((error: any) => {
    //       // Fingerprint/Face was not successfully verified
    //       console.error('error', error);
    //       // return this.nav.navigateRoot('/login');
    //     });
    // }
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  async getToken() {
    this.apiService.getToken().then(async (resp: any) => {
      this.utils.setStorage(
        { key: APP_CONFIG.ADMIN_TOKEN, value: resp })
    });
    this.translate.addLangs(this.storeViews.map(store => store.locale));

    let store = await this.utils.getStorage(APP_CONFIG.CURRENT_STORE_VIEW)

    console.log(store)
    if (!store) {
      this.langDefault = 'id_ID';

      if (this.langDefault == this.storeViews[0].locale) {
        this.translate.setDefaultLang(this.storeViews[1].locale);
        this.translate.setDefaultLang(this.storeViews[0].locale);
      }

      if (this.langDefault == this.storeViews[1].locale) {
        this.translate.setDefaultLang(this.storeViews[0].locale);
        this.translate.setDefaultLang(this.storeViews[1].locale);
      }
    } else {
      this.utils.setStorage({ key: APP_CONFIG.CURRENT_STORE_VIEW, value: JSON.stringify(this.storeViews[0]) });
      this.langDefault = this.storeViews[0].locale;
      this.translate.setDefaultLang(this.storeViews[1].locale);
      this.translate.setDefaultLang(this.storeViews[0].locale);
    }
  }

  backButton() {
    if (!this.routerOutlet.canGoBack()) {
      const that = this;
      let lastTimeBackPress = 0;
      const timePeriodToExit = 2000;
      function onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
          navigator['app'].exitApp();
        } else {
          that.presentToast();
          lastTimeBackPress = new Date().getTime();
        }
      }
      document.addEventListener('backbutton', onBackKeyDown, false);

    }
  }
  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: "Press again to exit",
      duration: 3000,
      position: "bottom"
    });
    toast.present();
  }




}
