let STAGING = {
  BASE_URL: 'https://staging-pti.testingnow.me',
  MEDIA_URL: 'https://staging-pti.testingnow.me/media/',
  ADMIN_USERNAME: 'webapi',
  ADMIN_PASSWORD: 'Admin!23'
};

let PRODUCTION = {
  // BASE_URL      : 'https://bigi.id',
  // MEDIA_URL     : 'https://bigi.id/media/',
  BASE_URL: 'https://bo.bigi.id',
  MEDIA_URL: 'https://bo.bigi.id/media/',
  ADMIN_USERNAME: 'webapi',
  ADMIN_PASSWORD: 'Admin!23'
};

// let LOCAL = {
//   BASE_URL      : 'http://172.16.13.145:8080',
//   MEDIA_URL     : 'http://bigi.id/media/',
//   ADMIN_USERNAME: 'admain',
//   ADMIN_PASSWORD: 'Admin@123'
// };

export const APP_CONFIG = {
  ENV: STAGING,
  TOPIC: {
    ANDROID: 'android-default',
    IOS: 'ios-default'
  },
  ADMIN_TOKEN: 'adminToken',
  CUSTOMER_TOKEN: 'customerToken',
  CUSTOMER_BASIC: 'customerBasic',
  CHANGE_LANGUAGE: 'changeLanguage',
  CATEGORIES: 'categories',
  RESET_PASSWORD_EMAIL_TEMPLATE: 'email_reset',
  RESET_PASSWORD_WEBSITE_ID: 1,
  BARCODE_TYPE: 'EAN_13,EAN_8,QR_CODE',
  SELECTED_SHIPPING_ADDRESS: 'selectedShippingAddressID',
  CMS_PAGE_API: 'V1/cmsPage/',
  INFINITE_SCROLL: false,
  FLASH_SALE_NAME: 'Flash Sale',
  FLASH_SALE_ID: '67',
  STORAGE_PER_PAGE_KEY: 'categoryPerPage',
  STORAGE_DIRECTION_KEY: 'categoryDirection',
  STORAGE_SORT_KEY: 'categorySortBy',
  NUMBER: '0216909344',
  DIRECTION_DEFAULT: 'ASC',
  SORT_DEFAULT: 'name',
  SORT_OPTIONS_NO_LOGIN: [
    {
      name: 'Nama Produk A-Z',
      value: 'name'
    },
    {
      name: 'Nama Produk Z-A',
      value: 'namedesc'
    },
    {
      name: 'Harga terendah',
      value: 'price'
    },
    {
      name: 'Harga tertinggi',
      value: 'pricedesc'
    },
    // {
    //   name : 'New Arrival Product',
    //   value: 'new_arrival'
    // }
  ],
  SORT_OPTIONS: [
    {
      name: 'Product Name A-Z',
      value: 'name'
    },
    {
      name: 'Product Name Z-A',
      value: 'namedesc'
    },
    {
      name: 'Price',
      value: 'price'
    },
    // {
    //   name : 'New Arrival Product',
    //   value: 'new_arrival'
    // }
  ],
  ITEM_PER_PAGE_DEFAULT: 12,
  ITEM_PER_PAGE_OPTIONS: [12, 24, 36, 100],
  PLACEHOLDER_IMAGE: 'assets/img/default-no-image.jpg',
  PRICE_LABEL: {
    configurable: 'From',
    bundle: {
      from: 'From',
      to: 'to'
    },
    grouped: 'Starting at'
  },
  UPLOAD_MAX_FILE_SIZE: '4MB',
  CURRENT_STORE_VIEW: 'currentStoreView',
  STORE_VIEWS: [
    {
      storeCode: 'default',
      locale: 'id_ID',
      locale_label: 'In',
      currencyCode: 'IDR',
      digitInfo: '1.2-2',
      id: 1
    },
    {
      storeCode: 'english_store_view',
      locale: 'en_US',
      locale_label: 'En',
      currencyCode: 'USD',
      digitInfo: '1.2-2',
      id: 2
    }
  ],
  LANGUAGE_DEFAULT: 'id_ID',
  STORE_DEFAULT: 'default',
  STORE_CONFIGS: 'storeConfigs'
};
export const CMS_PAGE_ID = {
  HOME: {
    en_US: '2',
    id_ID: '2'
  },
  ABOUT: {
    en_US: '8',
    id_ID: '16'
  },
  CUSTOMER_SERVICE: {
    en_US: '9',
    id_ID: '17'
  },
  PAYMENT_METHOD: {
    en_US: '13',
    id_ID: '21'
  },
  SHIPPING: {
    en_US: '10',
    id_ID: '18'
  },
  POLICY: {
    en_US: '12',
    id_ID: '20'
  },
  TERMS_AND_CONDITION: {
    en_US: '11',
    id_ID: '19'
  },
  RETURN: {
    en_US: '6',
    id_ID: '14'
  },
  PRODUCT_SERVICE: {
    en_US: '7',
    id_ID: '15'
  },
  BIGI_POINT: {
    en_US: '29',
    id_ID: '29'
  },
  CONTACT_US: {
    en_US: '28',
    id_ID: '26'
  }
};

export const MEDIA_URL = APP_CONFIG.ENV.MEDIA_URL;
export const PRODUCT_MEDIA_URL = APP_CONFIG.ENV.MEDIA_URL + 'catalog/product';
export const CATEGORY_MEDIA_URL = APP_CONFIG.ENV.MEDIA_URL + 'catalog/category';
export const BRAND_MEDIA_URL = APP_CONFIG.ENV.MEDIA_URL + 'sm/data/banner';
export const BRANDS_MEDIA_URL = APP_CONFIG.ENV.MEDIA_URL + 'brand/';
