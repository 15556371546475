import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'products/:id',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'modal-filter',
    loadChildren: () => import('./pages/modal-filter/modal-filter.module').then(m => m.ModalFilterPageModule)
  },
  {
    path: 'product-detail/:sku',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: 'modal-counter',
    loadChildren: () => import('./pages/modal-counter/modal-counter.module').then(m => m.ModalCounterPageModule)
  },
  {
    path: 'order-detail/:id',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
  },
  {
    path: 'modal-brand',
    loadChildren: () => import('./pages/modal-brand/modal-brand.module').then(m => m.ModalBrandPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'address-list',
    loadChildren: () => import('./pages/address-list/address-list.module').then(m => m.AddressListPageModule)

  },
  {
    path: 'modal-change-address',
    loadChildren: () => import('./pages/modal-change-address/modal-change-address.module').then( m => m.ModalChangeAddressPageModule)
  },
  {
    path: 'product-brand',
    loadChildren: () => import('./pages/product-brand/product-brand.module').then( m => m.ProductBrandPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'help-detail/:id',
    loadChildren: () => import('./pages/help-detail/help-detail.module').then( m => m.HelpDetailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'modal-success',
    loadChildren: () => import('./pages/modal-success/modal-success.module').then( m => m.ModalSuccessPageModule)
  },
  {
    path: 'bigi-egg',
    loadChildren: () => import('./pages/bigi-egg/bigi-egg.module').then( m => m.BigiEggPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'company-info',
    loadChildren: () => import('./pages/company-info/company-info.module').then( m => m.CompanyInfoPageModule)
  },
  {
    path: 'account-setting',
    loadChildren: () => import('./pages/account-setting/account-setting.module').then( m => m.AccountSettingPageModule)
  },
  {
    path: 'modal-info',
    loadChildren: () => import('./pages/modal-info/modal-info.module').then( m => m.ModalInfoPageModule)
  },
  {
    path: 'form-address',
    loadChildren: () => import('./pages/form-address/form-address.module').then( m => m.FormAddressPageModule)
  },
  {
    path: 'bigi-point',
    loadChildren: () => import('./pages/bigi-point/bigi-point.module').then( m => m.BigiPointPageModule)
  },
  {
    path: 'company-credit',
    loadChildren: () => import('./pages/company-credit/company-credit.module').then( m => m.CompanyCreditPageModule)
  },
  {
    path: 'company-deposit',
    loadChildren: () => import('./pages/company-deposit/company-deposit.module').then( m => m.CompanyDepositPageModule)
  },
  {
    path: 'security-account',
    loadChildren: () => import('./pages/security-account/security-account.module').then( m => m.SecurityAccountPageModule)
  },
  {
    path: 'check-sn',
    loadChildren: () => import('./pages/check-sn/check-sn.module').then( m => m.CheckSnPageModule)
  },
  {
    path: 'modal-connection',
    loadChildren: () => import('./pages/modal-connection/modal-connection.module').then( m => m.ModalConnectionPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
