import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { ApiAdminService } from '../api-admin/api-admin.service';
import { APP_CONFIG } from 'src/const-variables/app.config';
import { Http, RequestOptions, URLSearchParams, Headers } from '@angular/http';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // API path
  url: string = APP_CONFIG.ENV.BASE_URL + '/rest';
  timeout = 70000;
  constructor(private http: HttpClient, public utils: UtilitiesService, public http2: Http) {
  }

  public getDomFromStr(data: string, element: string) {
    if (!data && !element) return;
    let parser = new DOMParser();
    let sliderDoc = parser.parseFromString(data, 'text/html');
    return Array.from(sliderDoc.querySelectorAll(element));
  }

  public getImgSrc(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString;
    let element = <HTMLImageElement>div.firstChild;
    return element.src;
  }

  getToken() {
    let body = {
      username: APP_CONFIG.ENV.ADMIN_USERNAME,
      password: APP_CONFIG.ENV.ADMIN_PASSWORD
    };
    const endpoint = 'V1/integration/admin/token';
    console.log(endpoint)
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + '/' + endpoint, body)
        .toPromise()
        .then(
          res => {
            resolve(res);
          }, err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getData(endpoint, token) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      this.http.get(this.url + '/' + endpoint, { headers }).subscribe((res: any) => {
        resolve(res);
        // tslint:disable-next-line: align
      }, (err) => {
        reject(err);
      });
    });
  }
 
  getEpicorAPI(endpoint) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Access-Control-Allow-Origin': '*', 
      
        Authorization: "Basic " + btoa('MagentoUser' + ":" + 'P@ssw0rd'),
      });
      this.http.get(endpoint, {headers}).subscribe((res: any) => {
        resolve(res);
        // tslint:disable-next-line: align
      }, (err) => {
        reject(err);
      });
    });
  }
  
  postEpicorAPI(endpoint, body) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Access-Control-Allow-Origin': '*', 
      
        Authorization: "Basic " + btoa('MagentoUser' + ":" + 'P@ssw0rd'),
      });
      this.http.post(endpoint, body, {headers}).subscribe((res: any) => {
        resolve(res);
        // tslint:disable-next-line: align
      }, (err) => {
        reject(err);
      });
    });
  }
  
  getDataWithParams(endpoint, token, params) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      this.http.get(this.url + '/' + endpoint + '?' + params, { headers }).subscribe((res: any) => {
        resolve(res);
        // tslint:disable-next-line: align
      }, (err) => {
        reject(err);
      });
    });
  }

  async get(endpoint: string, params?: any, options?: RequestOptions) {
    return new Promise(async (resolve, reject) => {

      options = options || new RequestOptions();
      if (params) {
        let p = new URLSearchParams();
        for (let k in params) {
          p.set(k, params[k]);
        }
        options.search = !options.search && p || options.search;
      }

      let token = await this.utils.getStorage({ key: APP_CONFIG.ADMIN_TOKEN });
      let storeCode = await this.utils.getStorage({ key: APP_CONFIG.CURRENT_STORE_VIEW });
      let code = JSON.parse(storeCode.value);
      let selectedLang = await this.utils.getStorage({ key: APP_CONFIG.CHANGE_LANGUAGE });
      let langParams = '';

      if (token) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token.value);
        headers.append('Content-Type', 'application/json');
        options.headers = headers;
      }
      if (selectedLang && code && code != selectedLang) {
        langParams = '?___store=' + code + '&___from_store=' + selectedLang;
      }
      if (!selectedLang && code && code != APP_CONFIG.STORE_DEFAULT) {
        langParams = '?___store=' + code + '&___from_store=' + APP_CONFIG.STORE_DEFAULT;
      }
      if (selectedLang && code && code == selectedLang && code != APP_CONFIG.STORE_DEFAULT) {
        langParams = '?___store=' + code + '&___from_store=' + APP_CONFIG.STORE_DEFAULT;
      }
      // if (code) {
      //   console.log(code);
      //   this.utils.setStorage(
      //     { key: APP_CONFIG.CHANGE_LANGUAGE, value: JSON.stringify(code) })
      //   this.http2.get(this.url + '/' + JSON.stringify(code) + '/' + endpoint + langParams, options).subscribe((res) => {
      //     resolve(res);
      //   });
      // } else {
      this.http2.get(this.url + '/' + endpoint, options).subscribe((res) => {
        resolve(res.json());
      });;
      // }

    })
  }

}