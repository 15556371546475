import { Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/const-variables/app.config';
import { CommonService } from '../common/common.service';

import { Http, RequestOptions, URLSearchParams, Headers } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;



@Injectable()
export class ApiAdminService {
  public url: string = APP_CONFIG.ENV.BASE_URL + '/rest';
  ADMIN_TOKEN = APP_CONFIG.ADMIN_TOKEN;
  timeout = 70000;
  constructor(private http: Http,
    public commonFunctions: CommonService) { }

  get(endpoint: string, params?: any, options?: RequestOptions) {
    options = options || new RequestOptions();
    if (params) {
      let p = new URLSearchParams();
      for (let k in params) {
        p.set(k, params[k]);
      }
      options.search = !options.search && p || options.search;
    }
    let storageData = Promise.all([
      this.getStorage({key:this.ADMIN_TOKEN}),
      this.getStorage({key:APP_CONFIG.CURRENT_STORE_VIEW}),
      this.getStorage({key:APP_CONFIG.CHANGE_LANGUAGE})
    ]);
    return Observable.fromPromise(storageData).map(res => {
      let token = res[0];
      let code = res[1]['storeCode'];
      let selectedLang = res[2];
      let langParams = '';

      if (token) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        headers.append('Content-Type', 'application/json');
        options.headers = headers;
      }
      if (selectedLang && code && code != selectedLang) {
        langParams = '?___store=' + code + '&___from_store=' + selectedLang;
      }
      if (!selectedLang && code && code != APP_CONFIG.STORE_DEFAULT) {
        langParams = '?___store=' + code + '&___from_store=' + APP_CONFIG.STORE_DEFAULT;
      }
      if (selectedLang && code && code == selectedLang && code != APP_CONFIG.STORE_DEFAULT) {
        langParams = '?___store=' + code + '&___from_store=' + APP_CONFIG.STORE_DEFAULT;
      }
      if (code) {
        this.setStorage({ key: APP_CONFIG.CHANGE_LANGUAGE, value: code })
        return this.http.get(this.url + '/' + code + '/' + endpoint + langParams, options).timeout(this.timeout);
      } else {
        return this.http.get(this.url + '/' + endpoint, options).timeout(this.timeout);
      }
    });
  }

  post(endpoint: string, body: any, options?: RequestOptions) {
    options         = options || new RequestOptions();
    let storageData = Promise.all([
      this.getStorage({key:this.ADMIN_TOKEN}),
      this.getStorage({key:APP_CONFIG.CURRENT_STORE_VIEW}),
      this.getStorage({key:APP_CONFIG.CHANGE_LANGUAGE})
    ]);
    return Observable.fromPromise(storageData).flatMap(res => {
      let token = res[0];
      let code  = res[1]['storeCode'];
      let selectedLang = res[2];
      let langParams = '';

      if (token) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        options.headers = headers;
      }
      if(selectedLang && code && code !=selectedLang) {
        langParams='?___store='+code+'&___from_store='+selectedLang;
      }
      if(!selectedLang && code && code !=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if(selectedLang && code && code ==selectedLang  && code!=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if (code) {
        this.setStorage({ key: APP_CONFIG.CHANGE_LANGUAGE, value: code })
        return this.http.post(this.url + '/' + code + '/' + endpoint + langParams, body, options).timeout(this.timeout);
      } else {
        return this.http.post(this.url + '/' + endpoint, body, options).timeout(this.timeout);
      }
    });
  }

  put(endpoint: string, body: any, options?: RequestOptions) {
    options         = options || new RequestOptions();
    let storageData = Promise.all([
      this.getStorage({key:this.ADMIN_TOKEN}),
      this.getStorage({key:APP_CONFIG.CURRENT_STORE_VIEW}),
      this.getStorage({key:APP_CONFIG.CHANGE_LANGUAGE})
    ]);
    return Observable.fromPromise(storageData).flatMap(res => {
      let token = res[0];
      let code  = res[1]['storeCode'];
      let selectedLang = res[2];
      let langParams = '';

      if (token) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        options.headers = headers;
      }
      if(selectedLang && code && code !=selectedLang) {
        langParams='?___store='+code+'&___from_store='+selectedLang;
      }
      if(!selectedLang && code && code !=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if(selectedLang && code && code ==selectedLang  && code!=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if (code) {
        this.setStorage({ key: APP_CONFIG.CHANGE_LANGUAGE, value: code })
        return this.http.put(this.url + '/' + code + '/' + endpoint + langParams, body, options).timeout(this.timeout);
      } else {
        return this.http.put(this.url + '/' + endpoint, body, options).timeout(this.timeout);
      }
    });
  }

  delete(endpoint: string, body?: any, options?: RequestOptions) {
    options         = options || new RequestOptions();
    let storageData = Promise.all([
      this.getStorage({key:this.ADMIN_TOKEN}),
      this.getStorage({key:APP_CONFIG.CURRENT_STORE_VIEW}),
      this.getStorage({key:APP_CONFIG.CHANGE_LANGUAGE})
    ]);
    return Observable.fromPromise(storageData).flatMap(res => {
      let token = res[0];
      let code  = res[1]['storeCode'];
      let selectedLang = res[2];
      let langParams = '';

      if (token) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        options.headers = headers;
      }
      if(selectedLang && code && code !=selectedLang) {
        langParams='?___store='+code+'&___from_store='+selectedLang;
      }
      if(!selectedLang && code && code !=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if(selectedLang && code && code ==selectedLang  && code!=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if (code) {
        this.setStorage({ key: APP_CONFIG.CHANGE_LANGUAGE, value: code })
        return this.http.delete(this.url + '/' + code + '/' + endpoint + langParams, options).timeout(this.timeout);
      } else {
        return this.http.delete(this.url + '/' + endpoint, options).timeout(this.timeout);
      }
    });
  }

  patch(endpoint: string, body: any, options?: RequestOptions) {
    options         = options || new RequestOptions();
    let storageData = Promise.all([
      this.getStorage({key:this.ADMIN_TOKEN}),
      this.getStorage({key:APP_CONFIG.CURRENT_STORE_VIEW}),
      this.getStorage({key:APP_CONFIG.CHANGE_LANGUAGE})
    ]);

    return Observable.fromPromise(storageData).flatMap(res => {
      let token = res[0];
      let code  = res[1]['storeCode'];
      let selectedLang = res[2];
      let langParams = '';

      if (token) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        options.headers = headers;
      }
      if(selectedLang && code && code !=selectedLang) {
        langParams='?___store='+code+'&___from_store='+selectedLang;
      }
      if(!selectedLang && code && code !=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if(selectedLang && code && code ==selectedLang  && code!=APP_CONFIG.STORE_DEFAULT) {
        langParams='?___store='+code+'&___from_store='+APP_CONFIG.STORE_DEFAULT;
      }
      if (code) {
        this.setStorage({ key: APP_CONFIG.CHANGE_LANGUAGE, value: code })
        return this.http.put(this.url + '/' + code + '/' + endpoint + langParams, body, options).timeout(this.timeout);
      } else {
        return this.http.put(this.url + '/' + endpoint, body, options).timeout(this.timeout);
      }
    });
  }

  auth(body: any, options?: RequestOptions) {
    const endpoint = 'V1/integration/admin/token';
    return this.http.post(this.url + '/' + endpoint, body, options).timeout(this.timeout);
  }

  getToken() {
    let admin = {
      username: APP_CONFIG.ENV.ADMIN_USERNAME,
      password: APP_CONFIG.ENV.ADMIN_PASSWORD
    };
    this.auth(admin)
      .catch(this.commonFunctions.handlerError)
      .map(res => res.json())
      .subscribe(res => {
        // this.storage.set(this.ADMIN_TOKEN, res).then(() => {
        //   this.events.publish('adminToken:created', res);
        // });
      }, (error: any) => {
        // this.commonFunctions.showError(error, null, true);
      });
  }

  async setStorage(param) {
    return await Storage.set(param);
  }
  async getStorage(param) {
    return await Storage.get(param);
  }
  async removeStorage(param) {
    return await Storage.remove(param);
  }
  async clearStorage(param) {
    return await Storage.clear();
  }
}
