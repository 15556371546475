import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/const-variables/app.config';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  url: string = APP_CONFIG.ENV.BASE_URL + '/rest';
  constructor(public http: HttpClient) { }

  getStoreConfigs(token) {
    const endpoint = 'V1/store/storeConfigs';
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      this.http.get(this.url + '/' + endpoint, { headers }).subscribe((res: any) => {
        console.log(res);
        resolve(res);

      }, (err) => {
        reject(err);
      });
    });
  }

  getCmsPage(token, id) {
    const endpoint = 'default/V1/cmsPage/' + id;
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      this.http.get(this.url + '/' + endpoint, { headers }).subscribe((res: any) => {
        console.log(res);
        resolve(res);

      }, (err) => {
        reject(err);
      });
    });
  }
  
  getCmsBlock(token, id) {
    const endpoint = 'default/V1/cmsBlock/' + id;
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      this.http.get(this.url + '/' + endpoint, { headers }).subscribe((res: any) => {
        resolve(res);

      }, (err) => {
        reject(err);
      });
    });
  }

  // saveConfig(data) {
  //   return this.storage.set(APP_CONFIG.STORE_CONFIGS, data);
  // }

  // setConfigData(currentStore?: any, storeConfigs?: any) {
  //   if (currentStore && storeConfigs) {
  //     this.prepareData(currentStore, storeConfigs);
  //   }
  //   else if (!currentStore && storeConfigs) {
  //     this.storage.get(APP_CONFIG.CURRENT_STORE_VIEW).then(store => {
  //       this.prepareData(store, storeConfigs);
  //     }, () => {
  //       this.configNotFound();
  //     });
  //   }
  //   else if (currentStore && !storeConfigs) {
  //     this.storage.get(APP_CONFIG.STORE_CONFIGS).then(configs => {
  //       this.prepareData(currentStore, configs);
  //     }, () => {
  //       this.configNotFound();
  //     });
  //   }
  //   else {
  //     Promise.all([
  //       this.storage.get(APP_CONFIG.CURRENT_STORE_VIEW),
  //       this.storage.get(APP_CONFIG.STORE_CONFIGS)
  //     ])
  //       .then(([currentStore, configs]) => {
  //         this.prepareData(currentStore, configs);
  //       })
  //       .catch(() => {
  //         this.configNotFound();
  //       });
  //   }
  // }

  // prepareData(currentStore, configs) {
  //   let configData = configs.filter(config => config.code === currentStore.storeCode);
  //   if (configData && configData.length > 0) {
  //     this.digitInfo = currentStore.digitInfo;
  //     this.storeConfig = configData[0];
  //     this.currencyCode = this.storeConfig.base_currency_code;
  //   } else {
  //     this.configNotFound();
  //   }
  // }

  // configNotFound() {
  //   this.commonFunctions.hideLoading();
  //   this.commonFunctions.alertMessage('Store config not found', 'Error!', ['Ok']);
  // }

}
