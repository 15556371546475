import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from 'src/const-variables/app.config';
import { AlertController, LoadingController, ToastController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  LOCATION: string = 'location';
  isOpenError: boolean = false;
  public logout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public login: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  version: string = '1.0.0';
  CUSTOMER_TOKEN = APP_CONFIG.CUSTOMER_TOKEN;
  pageAfterLogin: any = false;
  paramsAfterLogin: any = false;
  viewPageAsPopup: any = false;
  cartBadge: any = false;
  myToast: any;
  loading: any;
  constructor(private alertController: AlertController,
    private toastCtrl: ToastController,
    private platform: Platform,
    private router: Router,
    private loadingController: LoadingController,
    private translate: TranslateService) {
  }

  showToast(message) {
    this.myToast = this.toastCtrl.create({
      message: message,
      duration: 2000
    }).then((toastData) => {
      toastData.present();
    });
  }
  showToastWithButton(message) {
    this.myToast = this.toastCtrl.create({
      message: message,
      duration: 3000,
      buttons: [
        {
          side: 'end',
          text: 'Lihat keranjang',
          handler: () => {

            this.router.navigate(['cart/']);
          }
        },
      ]
    }).then((toastData) => {
      toastData.present();
    });
  }
 
  showToastWithButtonWishlist(message) {
    this.myToast = this.toastCtrl.create({
      message: message,
      duration: 3000,
      buttons: [
        {
          side: 'end',
          text: 'Lihat wishlist',
          handler: () => {

            this.router.navigate(['wishlist/']);
          }
        },
      ]
    }).then((toastData) => {
      toastData.present();
    });
  }
  hideToast() {
    this.myToast = this.toastCtrl.dismiss();
  }

  async alertMessage(message: string, title: string, buttons: any[], cssClass?: string) {
    let opt: any = {
      title: title,
      message: message,
      buttons: buttons,
      enableBackdropDismiss: false
    };
    if (cssClass) {
      opt.cssClass = cssClass
    }
    let alert = await this.alertController.create(opt);
    await alert.present();
  }

  showloading() {
    this.translateGetKey('MSG.LOADING').toPromise().then(val => {
      if (val === 'MSG.LOADING') {
        val = 'Loading... Please wait.';
      }
      this.loading = this.loadingController.create({
        message: val
      });
      this.loading.present();
    });
  }

  hideLoading() {
    if (this.loading) {
      this.loading.dismiss().catch((err) => {
        console.log(err);
      });
    }
  }

  handlerError(error: Response | any) {
    let errMsg: any;
    if (error instanceof Response) {
      if (!error.url) {
        errMsg = {
          message: 'Please check your internet connection and try again.',
          code: error.status
        }
      } else {
        let body: any = {};
        try {
          body = error.json() || {};
        } catch (e) {
          body = {};
        }

        let err = body.message || 'Server error!';
        if (body.parameters) {
          for (let property in body.parameters) {
            if (body.parameters.hasOwnProperty(property)) {
              err = err.replace('%' + property, body.parameters[property]);
            }
          }
          if (Array.isArray(body.parameters)) {
            err = err.replace("%1", body.parameters[0]);
          }
        }
        let params: any = '';
        if (body.parameters) {
          for (let key in body.parameters) {
            params += '<span class="param-info"><span class="param-key">' + key + '</span>: ' + body.parameters[key] + '</span>';
          }
        }
        errMsg = {
          message: err + params,
          code: error.status
        };
      }
    } else {
      if (error.message && error.code) {
        errMsg = {
          message: error.message,
          code: error.code,
          checkPassword: error.checkPassword ? true : false
        };
      }
      else {
        errMsg = error.message || 'Server error!'
      }
    }
    return Observable.throw(errMsg);
  }

  // async showError(error: any, callback?: any, printConsole?: any) {
  //   this.hideLoading();
  //   if (error.code) {
  //     if (printConsole) {
  //       console.log(error.message);
  //     } else {
  //       this.isOpenError = false;
  //       if (error.code && error.code == 401 && !error.checkPassword) {
  //         console.log(401);
  //         if (!this.isOpenError) {
  //           this.storage.get(this.CUSTOMER_TOKEN).then((hasLoggedIn) => {
  //             if (hasLoggedIn) {
  //               this.translateGetKey(['COMMON.NOTICE', 'MSG.NEED_LOGIN', 'BUTTON.OK']).toPromise().then(async txt => {
  //                 let alert = await this.alertController.create({
  //                   subHeader: txt['COMMON.NOTICE'],
  //                   message: txt['MSG.NEED_LOGIN'],
  //                   cssClass: 'error-alert',
  //                   buttons: [
  //                     {
  //                       text: txt['BUTTON.OK'],
  //                       handler: () => {
  //                         this.isOpenError = false;
  //                         if (callback && typeof callback === 'function') {
  //                           callback();
  //                         }
  //                       }
  //                     }
  //                   ],
  //                 });
  //                 await alert.present();
  //               });
  //             } else {
  //               this.translateGetKey(['COMMON.NOTICE', 'BUTTON.OK']).toPromise().then(async txt => {
  //                 let alert = await this.alertController.create({
  //                   subHeader: txt['COMMON.NOTICE'],
  //                   message: error.message || "",
  //                   cssClass: 'error-alert',
  //                   buttons: [
  //                     {
  //                       text: txt['BUTTON.OK'],
  //                       handler: () => {
  //                         this.isOpenError = false;
  //                         if (callback && typeof callback === 'function') {
  //                           callback();
  //                         }
  //                       }
  //                     }
  //                   ],
  //                 });
  //                 await alert.present();
  //               });
  //             }
  //           });
  //         }
  //       } else if (error.code && error.code == 0) {
  //         if (!this.isOpenError) {
  //           this.translateGetKey(['COMMON.DISCONNECTED', 'BUTTON.OK']).toPromise().then(async txt => {
  //             let alert = await this.alertController.create({
  //               subHeader: txt['COMMON.DISCONNECTED'],
  //               message: error.message,
  //               cssClass: 'error-alert',
  //               buttons: [
  //                 {
  //                   text: txt['BUTTON.OK'],
  //                   handler: () => {
  //                     this.isOpenError = false;
  //                     if (callback && typeof callback === 'function') {
  //                       callback();
  //                     }
  //                   }
  //                 }
  //               ],
  //             });
  //             await alert.present();
  //           });
  //         }
  //       } else {
  //         if (!this.isOpenError) {
  //           this.translateGetKey(['COMMON.ERROR', 'BUTTON.OK']).toPromise().then(async txt => {
  //             let alert = await this.alertController.create({
  //               subHeader: txt['COMMON.ERROR'],
  //               message: error.message || error.errorMessage,
  //               cssClass: 'error-alert',
  //               buttons: [
  //                 {
  //                   text: txt['BUTTON.OK'],
  //                   handler: () => {
  //                     this.isOpenError = false;
  //                     if (callback && typeof callback === 'function') {
  //                       callback();
  //                     }
  //                   }
  //                 }
  //               ],
  //               // enableBackdropDismiss: false
  //             });
  //             await alert.present();
  //           });
  //         }
  //       }
  //     }
  //   }
  //   if (error.status) {
  //     if (error.status === 401) {
  //       console.log(error.message);
  //       this.storage.remove(APP_CONFIG.CUSTOMER_BASIC);
  //       // this.storage.remove(APP_CONFIG.CUSTOMER_TOKEN).then(() => {
  //       //   this.events.publish('customer-logout');
  //       // });
  //     }
  //     error = JSON.parse(error._body);
  //     if (printConsole) {
  //       console.log(error.message);
  //     } else {
  //       if (!this.isOpenError) {
  //         this.translateGetKey(['COMMON.ERROR', 'BUTTON.OK']).toPromise().then(async txt => {
  //           let alert = await this.alertController.create({
  //             subHeader: txt['COMMON.ERROR'],
  //             message: error.message,
  //             cssClass: 'error-alert',
  //             buttons: [
  //               {
  //                 text: txt['BUTTON.OK'],
  //                 handler: () => {
  //                   this.isOpenError = false;
  //                   if (callback && typeof callback === 'function') {
  //                     callback();
  //                   }
  //                 }
  //               }
  //             ],
  //           });
  //           await alert.present();
  //         });
  //       }
  //     }
  //   }
  //   if (!this.isOpenError) {
  //     this.isOpenError = true;
  //   }
  // }

  checkArray(arr, isSingle?: boolean) {
    if (arr && arr.length > 0) {
      if (isSingle) {
        return arr[0];
      } else {
        return arr;
      }
    } else {
      return false;
    }
  }

  isNull = obj => !obj || obj === '' || obj === null ? true : false;

  parseint = string => parseInt(string);

  trim(s) {
    s = s.replace(/(^\s*)|(\s*$)/gi, "");
    s = s.replace(/[ ]{2,}/gi, " ");
    s = s.replace(/\n /, "\n");
    return s;
  }

  isApp() {
    return (!document.URL.startsWith('http') || document.URL.startsWith('http://localhost:8080'));
    // return true;
  }

  isPlatform(name: any) {
    return this.platform.is(name);
  }

  translateGetKey(key: string | Array<string>) {
    return this.translate.get(key);
  }

  translateStreamKey(key: string | Array<string>) {
    return this.translate.stream(key);
  }

  getLocaleConfig(config) {
    return new Promise((resolve, reject) => {
      let code: any = false;
      code = config[this.translate.getDefaultLang()];
      if (!code || typeof code === 'undefined') {
        let interVal = setInterval(() => {
          if (code) {
            resolve(code);
            clearInterval(interVal);
          } else {
            code = config[this.translate.getDefaultLang()];
          }
        }, 250);
      } else {
        resolve(code);
      }
    });
  }

}
