import { Injectable } from '@angular/core';
import { MEDIA_URL } from 'src/const-variables/app.config';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  mediaUrl = MEDIA_URL;
  isLoading = false;
  constructor(private translate: TranslateService, private loadingController: LoadingController, private oneSignal: OneSignal
  ) { }

  public getDomFromStr(data: string, element: string) {
    if (!data && !element) return;
    let parser = new DOMParser();
    let sliderDoc = parser.parseFromString(data, 'text/html');
    return Array.from(sliderDoc.querySelectorAll(element));
  }

  public getImgSrc(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString;
    let element = <HTMLImageElement>div.firstChild;
    return element.src;
  }
 
  public getImgSrcd(htmlString) {
    var div = document.createElement('img');
    div.innerHTML = htmlString;
    let element = <HTMLImageElement>div.firstChild;
    return element.src;
  }

  public getImgSrcFromA(htmlString) {
    var div = document.createElement('a');
    div.innerHTML = htmlString;
    let element = <HTMLImageElement>div.firstChild;
    return element.src;
  }

  public getImgSrcPromo(elem) {

    var images = elem.getElementsByTagName("img");

    for (var i = 0; i < images.length; i++) {
      console.log(images[i].src);
    }
    // let p1 = element.toString().replace('')
  }

  isEmptyObject(obj) {
    for (let prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }


  findObjectInArray(arr, criteria) {
    return arr.filter(function (obj) {
      return Object.keys(criteria).every(function (c) {
        return obj[c] == criteria[c];
      });
    });
  }

  translateGetKey(key: string | Array<string>) {
    return this.translate.get(key);
  }

  countDiscount(p1, p2) {
    return (p1 / p2) * 100;
  }

  async presentLoading() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  async alertMessage(message, status) {
    Swal.fire({
      icon: status,
      text: message,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'my-swal'
      },
    })
  }


  async setStorage(param) {
    return await Storage.set(param);
  }
  async getStorage(param) {
    return await Storage.get(param);
  }
  async removeStorage(param) {
    return await Storage.remove(param);
  }
  async clearStorage(param) {
    return await Storage.clear();
  }

  async objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
  }

  setTags(currentTags, customerSegmentIDs) {
    let prefix: any = 'segment_';
    let tagsJson: any = {};
    _.each(customerSegmentIDs, (obj) => {
      tagsJson[prefix + obj] = obj;
    });
    if (_.isEmpty(currentTags)) {
      this.oneSignal.sendTags(tagsJson);
    } else {
      if (!_.isEqual(currentTags, tagsJson)) {
        _.each(customerSegmentIDs, (obj) => {
          this.oneSignal.deleteTag(prefix + obj);
        });
        this.oneSignal.sendTags(tagsJson);
      }
    }
  }

  checkTags() {
    let result = new Promise((resolve, reject) => {
      this.oneSignal.getTags().then(res => {
        resolve(res);
      }).catch(e => {
        console.log(e);
        reject(e);
      });
    });
    return result;
  }


}
